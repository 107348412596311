define(['app','siteObj'], (app, siteObj) => {
  const accountRegister = () => {
    const component = {};

    const _config = {
      selectors: {
        registerButton: '.accountSignUp_submitButton',
        registerError: '.accountSignUp_alert-danger',
      }
    }

    const _init = (element) => {
      component.element = element;
      component.registerButton = document.querySelector(component.config.selectors.registerButton);
      component.registerError = document.querySelectorAll(component.config.selectors.registerError);
      component.bind();
    };

    const _bind = (element) => {
      component.registerButton.addEventListener('click', () => component.checkErrors());
    }

    const _checkErrors = () => {
      if (document.querySelector(component.config.selectors.registerError)) {
        let errorList = [];
        errorList.forEach((error)=> {
          let singleError = {};
          const errorListItem = error.querySelectorAll(component.config.selectors.registerError);

          if (errorListItem) {
            singleError['form_error'] = errorListItem.querySelectorAll(component.config.selectors.registerError).span;
            errorList.push(singleError);
          }
        })
        window.dataLayer.push({
          'event': 'ElysiumEvent',
          'event_name':'login_failed',
          'error_value': errorList
        });
      }
    }

    component.config = _config;
    component.init = _init;
    component.bind = _bind;
    component.checkErrors = _checkErrors;
    return component;
  };

  return accountRegister;
});
